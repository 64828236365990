.ps-widget--footer {
    @extend %list-reset;

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    .ps-widget__links {
        a {
            display: block;
            color: #fff;
            line-height: 18px;
            padding: 8px 0;
            text-transform: capitalize;
            font-size: var(--font-size-default);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .ps-form--widget-subscribe {
        max-width: 455px;
    }
}

.ps-widget-footer-contact {
    p {
        margin-bottom: 20px;
        color: #ffffff;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ps-widget--popular-posts {
    .ps-post--horizontal {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-border);

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
        }
    }

}

.ps-widget--blog {
    .ps-widget__heading {
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 18px;
    }

    @media screen and (min-width: 1024px) {
        .ps-widget__heading {
            font-size: 24px;
        }
    }
}

.ps-widget--deparments {
    @extend %list-reset;

    .ps-widget__links {
        li {
            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 20px;
                padding: 10px 0;
                font-size: 14px;
                color: var(--color-text);

                &:hover {
                    color: var(--color-1st);
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-widget__links {
            li {
                a {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}

.ps-widget--project {
    .ps-widget__heading {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .ps-widget__attributes {
        .ps-checkbox {
            margin-bottom: 12px;

            label {
                color: var(--color-heading);
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                span {
                    color: var(--color-text-2);
                }

            }

            input[type=checkbox]:checked ~ label {
                color: var(--color-1st);

                &:before {
                    background-color: var(--color-1st);
                    border-color: var(--color-1st);
                }

                &:after {
                    @include transform(rotate(45deg));
                    opacity: 1;
                }

                span {

                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .ps-widget__heading {
            font-size: 24px;
            margin-bottom: 40px;
        }
    }
}
