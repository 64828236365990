* {
    font-family: $font-1st;
    font-weight: 400;
}

html {
    font-size: 62.5%;
}

body {
    font-size: $document-font-size;
}

h1, h2, h3, h4, h5, h6 {
    position: relative;
    color: var(--color-heading);
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 500;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

p {
    font-size: var(--font-size-default);
    line-height: 1.6em;
    color: $color-text;

    span {
        font-family: inherit;
        color: inherit;
        font-size: inherit;
    }
}

a {
    position: relative;
    color: inherit;
    text-decoration: none;
    font-size: var(--font-size-default);

    &:hover {
        color: $color-1st;
    }
}

a, button {
    cursor: pointer;
    transition: all .4s ease;
}

a,
input,
textarea,
button,
select {
    outline: none;
    font-size: var(--font-size-default);
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
    text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
}

img {
    max-width: 100%;
}

iframe {
    border: 0;
    max-width: 100%;
    width: 100%;
}

figure {
    margin: 0;
}

.ps-document {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 20px;
        font-weight: 500;
        color: var(--color-heading);
    }

    ul, ol {
        padding-left: 20px;

        li {
            font-size: var(--font-size-default);
            color: var(--color-text);
        }
    }

    > * {
        margin-bottom: 40px;
    }
}

p {
    a {
        font-size: inherit;
    }

    .ps-highlight {
        color: #06f;
        font-weight: inherit;
        font-size: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}

.ps-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    height: 28px;
    max-width: 100px;
    padding: 0 18px;
    border-radius: var(--border-radius-medium);
    font-weight: 500;
    text-transform: capitalize;

    &--info {
        color: var(--color-info);
        background-color: alpha(var(--color-info), 0.15);
    }

    &--success {
        color: var(--color-success);
        background-color: alpha(var(--color-success), 0.15);
    }

    &--waring {
        color: var(--color-warning);
        background-color: alpha(var(--color-warning), 0.15);
    }

    &--danger {
        color: var(--color-danger);
        background-color: alpha(var(--color-danger), 0.15);
    }
}
