$color-1st: var(--color-1st);
$color-2nd: var(--color-2nd);
$color-success: var(--color-success);
$color-info: var(--color-info);
$color-warning: var(--color-warning);
$color-danger: var(--color-danger);
$color-border: var(--color-border);
$color-text: var(--color-text);
$color-contrast: var(--color-contract);
$color-heading: var(--color-heading);
$color-gray: var(--color-gray);
$color-link: var(--color-1st);
