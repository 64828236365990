.ps-accordion {
    margin-bottom: 20px;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
    }

    &__heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;


        p {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
        }
    }

    &__header {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 50px;
        padding: 6px 0;
        cursor: pointer;
        border-bottom: 1px solid var(--color-border);

        p {
            margin-bottom: 0;
            line-height: 20px;
            font-weight: 700;
            color: $color-heading;
        }

        > span {
            @include vertical-align();
            right: 0;
            width: 40px;
            height: 40px;
            background-color: #222;

            i {
                @include center();
                color: #ffffff;
            }
        }
    }

    &__content {
        padding-top: 30px;
        display: none;

        > * {
            margin-bottom: 25px;
        }

        p {
            font-size: 14px;
        }
    }

    @media screen and (min-width: 768px) {
        &__heading {
            p {
                font-size: 24px;
            }
        }
        &__content {
            p {
                font-size: 16px;
            }
        }
    }
}

.ps-accordion--floor-plan {
    .ps-accordion__icon {
        width: 30px;
        height: 30px;
    }

    .ps-accordion__header {
        padding: 10px 24px;
        border-radius: var(--border-radius-medium);
        border: 1px solid var(--color-border);

        p {
            margin-bottom: 0;
            color: var(--color-text-2);
            align-self: center;

            strong {
                font-weight: 500;
                color: var(--color-heading);
            }
        }

        .ps-accordion__params {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            font-size: 15px;
        }
    }

    .ps-accordion__header-wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .ps-accordion__content {
        padding: 10px 24px;
        border-radius: var(--border-radius-medium);
        border: 1px solid var(--color-border);
        margin-top: 20px;
    }
}
