.ps-sidebar--shop {
    .ps-widget {
        margin-bottom: 30px;
    }
}

.ps-sidebar--blog {
    .ps-widget--blog {
        margin-bottom: 35px;
    }

    @media screen and (min-width: 1024px) {
        .ps-widget--blog {
            margin-bottom: 70px;
        }
    }
}
