.ps-panel--sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 470px;
    height: 100vh;
    overflow-y: auto;
    z-index: 10001;
    background-color: #fff;
    transform: translateX(100%);
    border-left: 1px solid #eaeaea;
    transition: transform .4s cubic-bezier(0.7, 0, .3, 1) 0s;
    @extend %list-reset;

    .ps-panel__close {
        font-size: 30px;
        color: $color-text;
    }

    .ps-panel__header {
        position: relative;
        padding: 20px 30px 0;
    }

    .ps-panel__content {
        height: 100%;
        padding: 30px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        > * {
            height: 100%;
            width: 100%;
        }
    }

    .ps-panel__bottom {
        max-height: 100px;
    }


    &.active {
        @include transform(translateX(0));
    }

    @media (min-width: 768px) {
        .ps-panel__close {
            font-size: 40px;
        }

        .ps-panel__header {
            padding: 20px 30px 0;
        }

        .ps-panel__content {
            padding: 50px;
        }
    }

}

.ps-panel--box {
    padding: 16px;
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--color-border);

    .ps-panel__heading {
        font-size: 24px;
    }

    .ps-panel__top {
        padding-bottom: 24px;
    }

    .ps-panel__pagination {
        padding-top: 30px;
    }


    @media screen and (min-width: 1024px) {
        padding: 32px;

        .ps-panel__top {
            padding-bottom: 48px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            > * {
                align-self: center;
            }

            .ps-panel__heading {
                margin-bottom: 0;
            }
        }

        .ps-panel__pagination {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
