.ps-navigation--sidebar {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    > * {
        flex-basis: 100%;
        height: 100%;
    }

    .ps-navigation__top {
        overflow: auto;
    }

    .ps-navigation__bottom {
        max-height: 100px;

        figcaption {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 600;
            color: #222;
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
        }
    }
}

.navigation--primary {

    .container {
        display: grid;
        grid-template-columns: 4fr 1fr;
    }

    .navigation__left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.navigation--bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid var(--color-border);
    z-index: 999;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .navigation__item {
        justify-self: center;
        align-self: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: var(--border-radius-medium);

            &.active {
                background-color: alpha(var(--color-1st), 0.1);
            }
        }
    }

    .navigation__wrapper {
        max-width: 300px;
        margin: 0 auto;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 1024px) {
        display: none;
    }
}
