html, body {
    min-height: 100%;
    position: relative;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
    .row--5-columns {
        .col-xl-3 {
            max-width: 20%;
        }
    }
}

body {
    overflow-x: hidden;
}

.container {
    @media screen and (min-width: 1200px) {
        max-width: 1170px;
    }

    @media screen and (min-width: 1366px) {
        max-width: 1280px;
    }

    @media screen and (min-width: 1680px) {
        max-width: 1540px;
    }
}


