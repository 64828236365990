.header {
    &__account-dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        padding: 8px;
        min-width: 180px;
        width: 100%;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius-medium);
        z-index: 999;
        background-color: #fff;
        @include hidden;
        transition: all .4s ease;

        .menu--single {
            > li {
                > a {
                    padding: 8px 16px;
                    color: var(--color-heading);
                    border-radius: var(--border-radius-medium);

                    &:hover {
                        background-color: alpha(var(--color-1st), 0.075);
                    }
                }
            }
        }
    }

    &__account {
        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 58px;
            padding: 0 30px;
            color: var(--color-heading);
            border-radius: var(--border-radius-medium);
            font-weight: 500;

            &:hover {
                color: #fff;
                background-color: var(--color-1st);
            }
        }

        i {
            margin-right: 16px;
            color: inherit;
        }

        &.logged-in {
            position: relative;

            &:hover {
                .header__account-dropdown {
                    @include show;
                }
            }
        }
    }

    &__submit-listing {
        .ps-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 58px;

            i {
                margin-left: 16px;
            }
        }
    }

    &__actions {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
    }
}

.header--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: 10px 0;
    background-color: #fff;
    border-bottom: 1px solid var(--color-border);

    .menu {
        > li {
            > a {
                padding-bottom: 16px;
            }
        }
    }

    .header__logo {
        .ps-logo.light {
            display: none;
        }
    }
}

.header--desktop {
    @extend %list-reset;

    .ps-logo {
        img {
            max-height: 46px;
        }

        &.light {
            display: none;
        }
    }

    .header__right {
        display: flex;
        justify-content: flex-end;

        .header__actions {
            margin-right: 10px;
        }
    }

    .header__social-links {
        padding: 0 10px;
        border-right: 1px solid #d9dee8;

        li {
            margin-right: 0;

            a {
                display: flex;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 0;

                i {
                    color: $color-gray;
                    font-size: 14px;
                    transition: all 0.4s ease;
                }

                &:hover {
                    background-color: transparent;

                    i {
                        color: $color-2nd;
                    }
                }
            }
        }
    }

    .ps-dropdown--fullscreen {
        position: static;
    }

    .header__categories-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: none;
        height: 40px;
        padding: 0 16px;
        min-width: 200px;
        line-height: 30px;
        text-transform: capitalize;
        border-radius: var(--border-radius-medium);
        color: #fff;
        background-color: var(--color-1st);

        i {
            margin-right: 6px;
            font-size: inherit;
            line-height: inherit;
            color: inherit;
            transition: all 0.4s ease;
        }

        span {
            font-weight: 500;
            font-size: 15px;
            font-size: inherit;
            line-height: inherit;
            transition: all 0.4s ease;
        }
    }

    .header__left {
        .header__toggle-btn {
            display: none;
        }
    }

    .header__top {
        padding: 20px 0;

        > .container {
            display: grid;
            grid-template-columns: 2fr 4fr 2fr;
        }
    }

    .header__bottom {
        padding: 5px 0;
        /* border-top: 1px solid var(--color-border);
         border-bottom: 1px solid var(--color-border);*/
        background-color: var(--color-bg-gray);

        .header__categories {
            margin-right: 20px;
        }
    }

    .header__wrapper {
        position: relative;
    }

    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.header--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    background-color: transparent;

    &.header--sticky {
        background-color: #fff;
        border-bottom: none;
        box-shadow: 1px 1px rgba(#ccc, 0.25);
    }
}

.header--transparent {
    &:not(.header--sticky) {
        .menu--desktop {
            > li {
                > a {
                    color: #fff;

                    &:after {
                        background-color: #fff;
                    }
                }
            }
        }

        .header__submit-listing {
            .ps-btn {
                background-color: rgba(#fff, 0.25);
                color: #ffffff;

                &:hover {

                    background-color: var(--color-1st);
                }
            }
        }

        .header__account {
            a {
                color: #ffffff;
            }
        }

        .ps-logo:not(.light) {
            display: none;
        }

        .ps-logo.light {
            display: block;
        }
    }
}

.header--one {
    .header__left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .ps-logo {
            margin-right: 145px;
        }
    }

    .header__actions {
        .header__account {
            margin-right: 10px;
        }
    }

    .header__wrapper {
        display: grid;
        grid-template-columns: 3fr 1.5fr;
        border-bottom: 1px solid var(--color-border);
        padding-top: 36px;
    }

    &.header--sticky {
        .header__account {
            a {
                height: 46px;
            }
        }

        .header__submit-listing {
            .ps-btn {
                height: 46px;
            }
        }

        .header__left, .header__right {
            align-items: center;
        }

        .header__wrapper {
            padding: 0;
            border-bottom: none;
        }
    }

    @media screen and (max-width: 1440px) {
        .header__wrapper {
            grid-template-columns: 5fr 2fr;
        }
    }
}

.header--mobile {
    position: relative;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 0 none;
    padding: 20px 16px;
    background-color: #fff;
    transition: all 0.4s $ease-in-out-quad;
    border-bottom: 1px solid var(--color-border);

    .ps-logo {
        line-height: 20px;

        img {
            max-height: 30px;
        }
    }


    .header__left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .header__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &.header--sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        padding: 10px 16px;
        border-bottom: 1px solid $color-gray;
    }

    @media (min-width: 1200px) {
        display: none;
    }
}

