.ps-theme-settings-toggle {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 999;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    border: none;
    border-radius:var(--border-radius-medium);
    background-color: var(--color-1st);
}
