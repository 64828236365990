:root {
    // colors
    @include defineColorHSL(--color-1st, 209, 100%, 38%); // #0065c3
    @include defineColorHSL(--color-2nd, 40, 100%, 49%); // #faa500
    @include defineColorHSL(--color-success, 80, 100%, 30%); // #669900
    @include defineColorHSL(--color-info, 0, 0%, 85%); // #d9d9d9
    @include defineColorHSL(--color-warning, 29, 98%, 57%); // #fd8d27
    @include defineColorHSL(--color-danger, 1, 77%, 55%); // #e53935
    @include defineColorHSL(--color-border, 214, 8%, 82%); // #ced1d5
    @include defineColorHSL(--color-border-active, 223, 10%, 52%); // #777e90
    @include defineColorHSL(--color-text, 0, 0%, 40%); // #666666
    @include defineColorHSL(--color-text-2, 0, 0%, 60%); // #99999
    @include defineColorHSL(--color-heading, 0, 0%, 0% ); // #000000
    @include defineColorHSL(--color-gray, 0, 0%, 80%); // #cccccc
    @include defineColorHSL(--color-bg-primary, 202, 28%, 62%); // #82a4b8
    @include defineColorHSL(--color-bg-gray, 210, 10%, 96%); // #f4f5f6

    // border-radius
    --border-radius-large: 8px;
    --border-radius-medium: 5px;
    --border-radius-small: 3px;
    --border-radius-tiny: 1px;

    // spaces

    --section-padding-top: 65px;
    --section-padding-bottom: 35px;
    --section-header-padding-bottom: 32px;
    // font-sizes
    --font-size-default: 16px;

    // height
    --input-height: 48px;
    --section-padding: 80px;

    @media screen and (min-width: 1024px) {
        --section-padding-top: 90px;
        --section-padding-bottom: 60px;
        --section-header-padding-bottom: 32px;
    }

    @media screen and (min-width: 1366px) {
        --section-padding-top: 130px;
        --section-padding-bottom: 100px;
        --section-header-padding-bottom: 65px;
    }
}
