.ps-rating {
    i {
        margin-right: 4px;
        font-size: 14px;
        color: var(--color-text);

        &.active {
            color: #faa500;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

#back2top {
    position: fixed;
    bottom: 60px;
    right: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);
    background-color: #fff;
    transition: all 0.25s ease;
    z-index: 999;
    cursor: pointer;

    i {
        font-size: 14px;
        color: var(--color-heading);
    }

    &:hover, &:focus {

        background-color: $color-1st;

        i {
            color: #fff;
        }
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.ps-site-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    @include hidden;
    background-color: rgba(#000, 0.5);
    transition: all .25s ease;

    &.active {
        @include show;
    }
}
