.ps-page--default {
    padding: 75px 0 50px;

    .ps-page__header {
        text-align: center;
        padding-bottom: 60px;
        @extend %list-reset;

        h3 {
            font-size: 32px;
        }
    }

    @media (min-width: 1200px) {
        padding: 90px 0 100px;

        .ps-page__header {
            padding-bottom: 80px;

            h3 {
                font-size: 48px;
            }
        }
    }
}

.ps-page--about {
    padding-top: 60px;

    @media (min-width: 768px) {
        padding-top: 100px;
    }

    @media (min-width: 1200px) {
        padding-top: 150px;
    }
}

.ps-page--404 {

    padding: 50px 0;

    @media screen and (min-width: 1024px) {
        padding: 100px 0;
    }
}

.ps-page--contact {
    padding-top: 0;

    .ps-page__header {
        padding: 100px 0;
    }

    @media (min-width: 1200px) {
        .ps-page__header {
            padding: 150px 0;
        }

    }
}

.ps-page--blog {
    padding-top: 40px;
    padding-bottom: 40px;

    .ps-page__header {
        padding-bottom: 35px;

        h1 {
            font-size: 28px;
        }

        .breadcrumb {
            margin-bottom: 0;
        }
    }

    .ps-blog--feature-posts {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid var(--color-border);
    }

    @media screen and (min-width: 480px) {
        .ps-page__header {
            h1 {
                font-size: 36px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding-top: 80px;
        padding-bottom: 80px;
        .ps-blog--feature-posts {
            padding-bottom: 85px;
            margin-bottom: 85px;
        }

        .ps-page__header {
            padding-bottom: 70px;

            h1 {
                font-size: 48px;
            }
        }

    }
}

.ps-page--post-single {
    padding-top: 32px;

    .breadcrumb {
        margin-bottom: 25px;
    }

    .ps-page__header {
        padding-bottom: 50px;
    }

    .ps-related-posts {
        padding-top: var(--section-padding-bottom);
        margin-bottom: var(--section-padding-bottom);
        border-top: 1px solid var(--color-border);
    }

    @media screen and (min-width: 1024px) {
        padding-top: 64px;
        .ps-page__header {
            padding-bottom: 100px;
        }

        .breadcrumb {
            margin-bottom: 50px;
        }
    }
}

.ps-page--inner {
    padding-top: 32px;

    .ps-page__heading {
        font-size: 30px;
        margin-bottom: 20px;
        line-height: 1em;
        font-weight: 500;
    }

    .breadcrumb {
        margin-bottom: 25px;
    }

    .ps-page__header {
    }

    .ps-page__hero {
        padding-bottom: 50px;

        img {
            width: 100%;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-page__heading {
            font-size: 36px;
            margin-bottom: 20px;
        }
    }

    @media screen and (min-width: 1024px) {
        padding-top: 64px;
        .ps-page__heading {
            font-size: 48px;
            margin-bottom: 20px;
        }

        .ps-page__hero {
            padding-bottom: 100px;
        }

        .breadcrumb {
            margin-bottom: 50px;
        }
    }
}

.ps-page--product {
    padding: 30px 0 0;

    .breadcrumb {
        background-color: transparent;

        li {
            a {
                color: var(--color-text);
            }
        }
    }

    .ps-page__header {
        display: flex;
        flex-flow: column wrap;
        padding-bottom: 50px;
        @extend %list-reset;
    }

    .ps-page__nav {
        display: none;

        button {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #bbb;
            padding: 0;

            i {
                font-size: 12px;
                line-height: 1em;
            }

            &:hover {
                border-color: $color-1st;
                color: $color-1st;
            }
        }
    }

    .ps-related-products {
        padding: 50px 0;
    }

    .ps-shop--sidebar {
        padding-top: 0;
    }

    .ps-product--detail {
        margin-bottom: 60px;
    }

    @media (min-width: 1200px) {
        .ps-related-products {
            padding: 80px 0;
        }
    }

}

.ps-shop--search {
    background-color: #fff;
}

.ps-page--shopping {
    .ps-page__header {
        margin: 30px 0;
        padding: 60px 0 30px;

        h3 {
            margin-bottom: 0;
            font-size: 36px;
            font-weight: 600;
            color: var(--color-heading);
        }

        .breadcrumb {
            margin-bottom: 0;
            background: none;
            padding-left: 0;
        }

        &.with-background {
            margin: 30px 0 50px;
            padding: 90px 30px 30px;
            background-color: var(--color-1st);
            border-radius: var(--border-radius-large);
            box-shadow: 0 2.3px 3.2px -16px rgba(0, 0, 0, 0.053),
            0 9.2px 10.7px -16px rgba(0, 0, 0, 0.065),
            0 30px 48px -16px rgba(0, 0, 0, 0.12);

            h3 {
                color: #fff;
            }

            .breadcrumb {
                margin-bottom: 10px;

                li {
                    color: #fff;

                    a {
                        color: darken(#fff, 10%);

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }

        }
    }
}

.ps-page--projects {
    .ps-form--projects-search {
        margin-bottom: 50px;
    }

    .ps-page__content {
        padding-bottom: var(--section-padding-bottom);
    }

    @media screen and (min-width: 1200px) {
        .container-fluid {
            padding: 0 40px;
        }
    }

    &.ps-page--fullwidth {
        .ps-page__header {
            padding-top: 40px;
        }
    }
}

.ps-page--single-property {
    .ps-page__header {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .ps-section--single-project {
        margin-bottom: 30px;
        border-bottom: 1px solid var(--color-border);
    }

    .ps-project-listing {
        margin-bottom: 20px;

        .container {
            padding: 0;
        }
    }


    @media screen and (min-width: 1024px) {
        .ps-page__header {
            padding-top: 60px;
            padding-bottom: 40px;
        }
        .ps-project-listing {
            margin-bottom: 30px;

            .ps-section__heading {
                font-size: 30px;
            }
        }
    }

    @media screen and (min-width: 1366px) {

        .ps-project-listing {
            margin-bottom: 50px;

            .ps-section__header {
                padding-bottom: 50px;
                align-items: flex-end;
            }
        }
        .ps-section--single-project {
            margin-bottom: 80px;

        }

    }
}

.ps-page--auth {
    .ps-page__content {
        padding-bottom: var(--section-padding-bottom);
    }
}

.ps-page--account {
    padding-bottom: 40px;
    @media screen and (min-width: 1024px) {
        padding-bottom: 40px;
    }
    @media screen and (min-width: 1366px) {
        padding-bottom: 80px;
    }
}

#homepage-one {
    > * {
        margin-bottom: 65px;
    }

    .with-bg {
        margin-bottom: 0;
    }

    .ps-top-banners {
        margin-top: 40px;
    }

    .ps-home-search {
        margin-bottom: 0;
    }

    .ps-site-stats {
        padding-top: 65px;
    }

    .ps-property-types {
        margin-bottom: 0px;
    }

    .ps-project-listing {
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1024px) {
        > * {
            margin-bottom: 90px;
        }

        .ps-home-search {
            padding-top: 150px;
        }

        .ps-site-stats {
            position: relative;
            z-index: 999;
            top: -80px;
            margin-bottom: -80px;
            padding-top: 90px;
            padding-bottom: 90px;
            border-radius: 50px 50px 0 0;
            background-color: #fff;
            overflow: hidden;
        }

        .ps-property-types {
            margin-bottom: 30px;
        }

        .ps-highlight-posts, .ps-project-listing {
            margin-bottom: 60px;
        }
    }

    @media screen and (min-width: 1366px) {
        > * {
            margin-bottom: 130px;
        }
        .ps-site-stats {
            padding-top: 130px;
            padding-bottom: 130px;
        }

        .ps-property-types {
            margin-bottom: 70px;
        }

        .ps-highlight-posts, .ps-project-listing {
            margin-bottom: 100px;
        }
    }
}

#homepage-two {
    > * {
        margin-bottom: 65px;
    }

    .ps-property-types {
        .owl-stage-outer {
            padding-bottom: 0;
        }

        .ps-section__header {
            padding-bottom: 35px;
        }

    }

    .ps-submit-property {
        margin-bottom: 0;

        .container {
            border-top: 1px solid var(--color-border);
        }
    }

    > .ps-section.with-background {
        padding-top: var(--section-padding-top);
        padding-bottom: var(--section-padding-bottom);
    }

    .ps-testimonials-and-stats {
        margin-bottom: 0;
    }

    .ps-neighborhoods {
        padding-bottom: 20px;
    }

    .ps-project-listing {
        &.with-bg {
            padding-bottom: calc(var(--section-padding-top) - 30px);
        }
    }

    .ps-testimonials {
        padding: 0;
    }

    @media screen and (min-width: 1024px) {
        > * {
            margin-bottom: 90px;
        }

        .ps-property-types {
            .ps-section__header {
                padding-bottom: 70px;
            }
        }

        .ps-testimonials-and-stats {
            margin-bottom: 0;
        }
    }


    @media screen and (min-width: 1366px) {
        > * {
            margin-bottom: 130px;
        }

        .ps-testimonials-and-stats {
            margin-bottom: 0;
        }

        .ps-project-listing:not(.with-bg) {
            margin-bottom: 100px;
        }
    }
}

#about-us {

    .ps-testimonials {
        max-width: 1540px;
        margin: 0 auto;
    }

    .ps-leaderships {
    }

    .ps-page__content {
        > * {
            margin-bottom: var(--section-padding-bottom);
        }

        .ps-our-services {
            margin-bottom: 0;
        }

    }
}

#contact-us {
    .ps-contact-information {
        padding: 50px 0;
    }


    @media screen and (min-width: 1024px) {
        .ps-contact-information {
            padding: 100px 0;
        }
    }
}

#services {
    .ps-page__header {
        .breadcrumb {
            margin-bottom: 0;
        }
    }

    .ps-testimonials {
        max-width: 1540px;
        margin: 0 auto;
        padding-top: 0;
    }

    .ps-faqs {
        padding-bottom: 30px;
        @media screen and (min-width: 1024px) {
            padding-bottom: 50px;
        }
    }
}

#careers {
    .ps-career-detail {
        padding-bottom: var(--section-padding-bottom);
    }

    .ps-page__content {
        > * {
            margin-bottom: 50px;
        }

        .ps-career-images {
            margin-bottom: 20px;
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-page__content {
            > * {
                margin-bottom: 80px;
            }

            .ps-career-images {
                margin-bottom: 50px;
            }
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-page__content {
            > * {
                margin-bottom: 100px;
            }

            .ps-career-images {
                margin-bottom: 70px;
            }
        }
    }
}

#agents {
    .ps-agents {
        padding-bottom: var(--section-padding-bottom);
    }

    .ps-page__header {
        .ps-form--agent-search {
            margin-bottom: 50px;
        }
    }

    .ps-agent-information {
        padding-bottom: var(--section-padding-bottom);
    }

    .ps-agent-properties {
        margin-bottom: 50px;
    }

    .ps-layout--with-sidebar {
        padding-bottom: var(--section-padding-bottom);
    }

    @media screen and (min-width: 1024px) {
        .ps-page__header {
            .ps-form--agent-search {
                margin-bottom: 70px;
            }
        }

        .ps-agent-properties {
            margin-bottom: 80px;

        }
    }

}

