.ps-table {
    .ps-table__actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        a {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: alpha(var(--color-1st), 0.05);
            border-radius: var(--border-radius-medium);

            &:hover {
                background-color: alpha(var(--color-1st), 0.25);
            }
        }
    }

    thead {
        > tr {
            > th {
                padding: 16px 0;
                font-size: var(--font-size-default);
                font-weight: 500;
                color: var(--color-heading);
                border-bottom: 1px solid var(--color-border);
                border-top: none;
            }
        }
    }

    tbody {
        > tr {
            > td {
                vertical-align: middle;
                padding: 16px 0;
                border: none;
                font-size: var(--font-size-default);
                color: $color-text;
                border-bottom: 1px solid #ddd;
            }

            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }

    @media (max-width: 768px) {
        border: none;

        thead {
            display: none;
        }

        tbody {
            display: block;

            tr {
                display: block;
                margin-bottom: 10px;
                border: 1px solid $color-border;


                td[data-label] {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: none;
                    padding: 12px 8px;
                    border-top: 1px solid $color-border;

                    &:before {
                        content: attr(data-label) ":";
                        font-weight: 400;
                    }

                    &:first-child {
                        border-top: none;
                    }

                    &.ps-table__item {
                    }
                }
            }
        }

    }

    &.ps-table--fixed {
        @media screen and (min-width: 1200px) {
            table-layout: fixed;
        }
    }


}
