/*
    CSS for shop pages
*/

.ps-shop {
    &__layout-switcher {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: var(--border-radius-medium);
        border: 2px solid var(--color-border);

        i {
            font-size: 20px;
            color: $color-text;
        }

        &:hover, &.active {
            border-color: var(--color-border-active);

            i {
                color: $color-heading;
            }
        }
    }

    &__layout-switchers {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a:first-child {
            margin-right: 10px;
        }
    }

    &__actions-left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        p {
            margin-bottom: 0;
            font-size: 16px;
            color: var(--color-heading);
        }
    }

    &__actions-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .ps-shop__sortby {
            margin-right: 10px;
        }
    }

    &__header {
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid var(--color-border);
    }
}

