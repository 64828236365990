.ps-banner {
    position: relative;
    border-radius: var(--border-radius-large);

    .ps-banner__image {
        overflow: hidden;
        border-radius: var(--border-radius-large);
    }

    p {
        font-size: 16px;
        color: var(--color-heading);
        font-weight: 300;
        margin-bottom: 0;
    }

    h3 {
        font-size: 48px;
        font-weight: 600;
        color: var(--color-heading);
    }

    h5 {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 400;

        del {
            color: var(--color-danger);
            font-size: 0.75em;
        }
    }

    .ps-btn {
        max-width: 160px;
    }

    @media screen and (min-width: 1200px) {
        .ps-banner__content {
            @include vertical-align();
            padding: 0 50px;
        }
    }

}
