.ps-btn, button.ps-btn {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    border: none;
    font-weight: 500;
    transition: all .4s ease;
    color: #ffffff;
    background-color: $color-1st;
    cursor: pointer;
    border-radius: var(--border-radius-medium);

    span {
        font-weight: inherit;
    }


    &:hover, &:active {
        color: #fff;
        background-color: lightness(var(--color-1st), 0.85);

        &.ps-btn--black {
            background-color: $color-1st;
        }
    }

    &--rounded {
        border-radius: 50px;
    }

    &--curve {
        border-radius: 5px;
    }

    &--outline {
        color: $color-heading;
        border: 2px solid var(--color-border);
        background-color: transparent;

        &:hover {
            color: $color-heading;
            background-color: #fff;
            border-color: var(--color-border-active);
        }
    }

    &--fullwidth {
        width: 100%;
        text-align: center;
    }

    &--sm {
        padding: .5rem 2rem;
        font-size: 1.2rem;

        &.ps-btn--curve {
            border-radius: 3px;
        }
    }

    &--lg {
        padding: 1.5rem 4rem;
    }

    &--xl {
        padding: 2rem 6rem;
        font-size: 1.6rem;
    }

    &--reverse {
        background-color: #576391;

        &:hover {
            background-color: $color-2nd;
        }
    }

    &--gray {
        color: var(--color-text);
        background-color: #f0f5fb;

        &:hover {
            color: #fff;
            background-color: var(--color-1st);
        }
    }

    &--black {
        background-color: $color-heading;

        &.ps-btn--outline {
            background-color: transparent;
            border: 2px solid $color-heading;
            color: $color-heading;

            &:hover {
                background-color: $color-heading;
                color: #ffffff;
            }
        }
    }

    &--with-opacity {
        color: var(--color-1st);
        background-color: alpha(var(--color-1st), 0.2);

        &:hover {
            color: #fff;
            background-color: var(--color-1st);
        }
    }

}

.ps-btn--close {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    transition: all .4s ease;
    border-radius: 50%;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 50%;
        background-color: #9f9a98;
        @include transform-origin(center center);
        transition: all .4s ease;
    }

    &:before {
        @include transform(translate(-50%, -50%) rotate(45deg));
    }

    &:after {
        @include transform(translate(-50%, -50%) rotate(-45deg));
    }

    &:hover {
        @include transform(rotate(180deg));

        &:before, &:after {
            background-color: #f44336;
            transition-delay: .3s;
        }
    }
}

.ps-btn--backtop {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 36px;
    height: 36px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #eaeaea;

    &:hover {
        background-color: $color-1st;
        border-color: $color-1st;
        color: #fff;
    }
}

.ant-back-top {

    right: 30px !important;
    bottom: 30px !important;


}
