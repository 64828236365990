.ps-form--widget-subscribe {
    p {
        margin-bottom: 0;
        color: #fff;
    }

    .ps-form__top {
        padding-bottom: 30px;
    }

    .ps-form__content {
        display: grid;
        grid-template-columns: 40px 1fr 40px;
        padding: 10px;
        margin-bottom: 30px;
        background-color: #fff;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius-medium);

        .ps-form__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: var(--color-heading)
        }

        input {
            padding: 0;
            height: 38px;
            border: none;
            font-size: 15px;
            font-weight: 400;
            color: var(--color-heading);
            @include placeholder() {
                font-size: 15px;
                font-weight: 400;
                color: var(--color-text);
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            border-radius: var(--border-radius-medium);
            border: none;
            background-color: transparent;

            &:hover {
                background-color: alpha(var(--color-1st), 0.1);
            }
        }
    }

    .ps-form__bottom {
        a {
            text-decoration: underline;

            &:hover {
                color: var(--color-1st);
            }
        }
    }
}

.ps-form--blog-search {
    position: relative;

    button {
        @include vertical-align();
        right: 0;
        background-color: transparent;
        border: none;

    }

    input {
        border: none;
        color: var(--color-text);
        font-size: 16px;
        border-radius: 0;
        border-bottom: 1px solid var(--color-border);
        padding-left: 0;
        font-weight: 400;
        @include placeholder() {
            color: var(--color-text);
            font-size: 16px;
            font-weight: 400;
        }
    }
}

.ps-form--widget-newsletter {
    padding: 40px;
    border-radius: var(--border-radius-medium);
    background-color: #f3f9ff;

    p {
        margin-bottom: 0;
        color: $color-text;
        font-size: 16px;
    }

    .ps-form__header {
        padding-bottom: 40px;
    }

    .ps-form__content {
        display: grid;
        grid-template-columns: 40px 1fr;
        padding: 10px;
        margin-bottom: 12px;
        background-color: #fff;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius-medium);

        .ps-form__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: var(--color-heading)
        }

        input {
            padding: 0;
            height: 32px;
            border: none;
            font-size: 15px;
            font-weight: 400;
            color: var(--color-heading);
            @include placeholder() {
                font-size: 15px;
                font-weight: 400;
                color: var(--color-text);
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            height: 52px;
            border-radius: var(--border-radius-medium);
            border: none;
            background-color: transparent;

            &:hover {
                background-color: alpha(var(--color-1st), 0.1);
            }
        }
    }

    .ps-form__bottom {
        a {
            text-decoration: underline;

            &:hover {
                color: var(--color-1st);
            }
        }
    }
}

.ps-form--post-comment {
    padding-bottom: 32px;

    .ps-form__top {
        padding-bottom: 32px;

        h3 {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 24px;
        }
    }

    .ps-form__bottom {
        padding-top: 30px;
    }

    @media screen and (min-width: 1024px) {
        padding-bottom: 64px;
        .ps-form__top {
            padding-bottom: 64px;

            h3 {
                font-size: 30px;
            }
        }
    }
}

.ps-form--contact-us {
    .ps-form__heading {
        margin-bottom: 0;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--color-text-2);
        font-weight: 400;
    }

    .form-group {
        margin-bottom: 20px;
    }

    .ps-form__top {
        padding-bottom: 30px;
    }

    .ps-form__bottom {
        padding-top: 50px;
    }

    @media screen and (min-width: 1024px) {
        .ps-form__top {
            padding-bottom: 60px;
        }
    }
}

.ps-form--agent-search {
    padding: 40px;
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--color-border);

    .ps-form__submit {
        border-bottom: none;

        .ps-btn {
            width: 100%;
        }
    }
}

.ps-form--agent-review {
    .ps-form__top {
        padding-bottom: 35px;

        h4 {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
        }

        p {
            margin-bottom: 0;

            sup {
                color: var(--color-danger);
            }
        }
    }

    .ps-form__bottom {
        padding-top: 50px;
    }
}

.ps-form--agent-contact {
    .ps-checkbox {
        label {
            a {
                color: var(--color-1st);

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .ps-form__bottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;

    }
}

.ps-form--project-schedule-tour {
    border: 1px solid var(--color-border);
    padding: 16px;
    border-radius: var(--border-radius-medium);

    .ps-form__bottom {
        padding-top: 50px;
    }

    .form-control {
        color: var(--color-text-2);
        @include placeholder() {
            @include show;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 40px;
    }
}

.ps-form--mortgage_caculator {

    .ps-form {
        label {
            color: $color-text;
        }

        .ps-form__icon {
            left: auto;
            right: 0;
            font-style: normal;
            color: var(--color-text-2);
        }

        select {
            color: var(--color-text);
            font-weight: 400;
        }

        .form-control {
            text-indent: 0;
        }
    }
}

.ps-form--projects-search {

    .form-group--with-slider {
        > label {
            display: flex;
            justify-content: flex-start;
            grid-column-gap: 10px;
            margin-bottom: 25px;

            i {
                display: block;

            }
        }
    }

    .ps-form__top-left {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius-medium);
        overflow: hidden;

        .ps-btn {
            border-radius: 0;
            height: 100%;
        }
    }

    .ps-form__top-right {
        grid-column-gap: 6px;
        display: grid;
        grid-template-columns: 1fr 1fr;

    }

    .ps-form__items {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-column-gap: 40px;
    }

    .ps-form__top {
        display: grid;
        /* grid-template-columns: 200px 1fr 340px;
         grid-column-gap: 40px;*/
        .ps-btn {
            height: 52px;
        }

        .ps-form__top-left {
            .ps-btn {
                height: 50px;
            }
        }

        .ps-form__actions {
            grid-column-gap: 6px;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .ps-btn--gray {
                padding: 0 10px;
                color: var(--color-heading);
                background-color: transparent;

                i {
                    margin-left: 0.5em;
                }

                &:hover {
                    background-color: var(--color-bg-gray);
                }
            }
        }
    }

    .ps-form__features {
        .ps-form__grid {

        }
    }

    .ps-form__feature-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

    }

    .ps-form__features {
        > p {
            font-size: 14px;
            text-transform: uppercase;
            color: var(--color-heading);
            margin-bottom: 25px;
        }
    }

    .ps-form__block {
        margin-bottom: 15px;
    }

    .ps-form__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column-gap: 20px;
        padding-top: 25px;

        .ps-btn {
            max-width: 200px;
        }
    }

    .ps-form__bottom {
        display: none;
        padding-top: 30px;
    }

    @media screen and (min-width: 768px) {
        .ps-form__feature-items {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        .ps-form__features {
            > p {
                margin-bottom: 50px;
            }
        }

        .ps-form__submit {
            padding-top: 50px;
            grid-column-gap: 40px;

            .ps-btn {
                min-width: 230px;
                height: 50px;
            }
        }
        .ps-form__block {
            margin-bottom: 35px;
        }
        .ps-form__bottom {
            padding-top: 60px;
        }
    }

    @media screen and (min-width: 1280px) {
        .ps-form__top-right {

            grid-template-columns: 1fr 340px;
            grid-column-gap: 40px;
        }

        .ps-form__top {

            grid-template-columns:200px 1fr;
            grid-column-gap: 20px;
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-form__top-right {
            grid-template-columns: 1fr 340px;
            grid-column-gap: 40px;
        }
        .ps-form__top {
            grid-template-columns:200px 1fr;
            grid-column-gap: 40px;
        }
    }

    @media screen and (max-width: 1200px) {
        .ps-form__top-left {
            max-width: 200px;
        }

        .ps-form__items {
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
            margin-bottom: 20px;

            .ps-form:first-child {
                grid-column: 1 / -1;
            }
        }

        .ps-form__top-right {
            grid-template-columns: 3fr 1fr;
            grid-column-gap: 30px;

            .ps-form__actions {
                display: flex;
                flex-flow: column-reverse wrap;
                justify-content: flex-end;
                align-items: flex-end;
                grid-column-gap: 0px;

                > * {
                    width: 100%;
                }

                .ps-btn--gray {
                    margin-top: 10px;
                    background-color: alpha(var(--color-1st), 0.1);
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .ps-form__top-right {
            grid-template-columns: 1fr;
        }
    }
}

.ps-form--projects-search-2 {

    .ps-form {
        .ps-form__icon {
            font-size: 20px;
            color: #999999;
        }

        .second {
            left: auto;
            right: 0;
        }
    }

    .ps-form__top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-column-gap: 4px;

        .ps-btn {
            width: 110px;
            color: #fff;
            background-color: rgba(#fff, 0.5);
            border-radius: 6px 6px 0 0;

            &.active {
                background-color: #fff;
                color: var(--color-1st);
            }
        }
    }

    .ps-form__bottom {
        padding: 30px;
        background-color: #fff;
        border-radius: 0 6px 6px 6px;

        .ps-btn {
            height: 48px;
            margin-bottom: 10px;
        }

        .ps-form__toggle {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 46px;
            max-width: 180px;
            color: $color-heading;

            i {
                margin-left: 20px;
            }
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-form__bottom {
            .ps-btn {
                font-size: 18px;
                height: 60px;
            }
        }
    }
}

.ps-form--projects-search-3 {

    .ps-form {
        padding-left: 0;

        input {
            text-indent: 0 !important;
            padding-left: 0 !important;
        }

        .ps-form__icon {
            font-size: 20px;
            color: #999999;
        }

        .second {
            left: auto;
            right: 0;
        }

    }

    .ps-form__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;


        > * {
            align-self: center;
        }
    }

    .ps-form__fields {
        .form-group {
            margin-bottom: 0;
        }
    }

    .ps-form__top {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column-gap: 4px;
        position: relative;
        top: 1px;

        .ps-btn {
            width: 150px;
            color: var(--color-text);
            background-color: #efefef;
            border-radius: 6px 6px 0 0;
            border: 1px solid var(--color-border);
            border-bottom: none;

            &.active {
                background-color: #fff;
                color: var(--color-1st);

            }
        }
    }

    .ps-form__bottom {
        display: grid;
        grid-template-columns: 3fr 2fr;
        padding: 30px;
        background-color: #fff;
        grid-gap: 30px;
        border-radius: var(--border-radius-medium);
        border: 1px solid var(--color-border);

        .ps-btn {
            height: 48px;
            margin-bottom: 10px;
        }

        .ps-form__toggle {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 46px;
            max-width: 180px;
            color: $color-heading;

            i {
                margin-left: 20px;
            }
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-form__bottom {
            .ps-btn {
                font-size: 18px;
                height: 60px;
            }
        }
    }

    @media screen and (max-width: 479px) {

        .ps-form__bottom {
            grid-template-columns: 1fr;
        }

        .ps-form__actions {
            display: flex;
            flex-flow: column-reverse nowrap;
            grid-template-columns: 1fr;
        }
    }
}

.ps-form--projects-search-extra {
    .ps-form__top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-column-gap: 4px;

        .ps-btn {
            width: 110px;
            color: #fff;
            background-color: rgba(#fff, 0.5);
            border-radius: 6px 6px 0 0;

            &.active {
                background-color: #fff;
                color: var(--color-1st);
            }
        }
    }

    .ps-form__extra {
        padding: 30px;
        border-radius: 0 6px 6px 6px;
        background-color: #fff;;
    }

    @media screen and (max-width: 480px) {
        .ps-form__actions {
            padding-top: 30px;

            .row {
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 10px;
            }
        }
    }
}

.ps-form--auth {
    margin: 0 auto;
    max-width: 500px;
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--color-border);
    padding: 30px 16px;

    > * {
        margin-bottom: 16px;
    }

    .ps-form__top {
        margin: 0;
        padding-bottom: 40px;

        h3 {
            font-size: 28px;
            line-height: 1em;
            margin-bottom: 16px;
            text-transform: capitalize;
        }

        p {
            margin-bottom: 0;
        }
    }

    .ps-form__methods {
        display: grid;
        grid-row-gap: 16px;

        a {

            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            font-weight: 500;
            border: 1px solid var(--color-border);
            border-radius: var(--border-radius-medium);
            background-color: alpha(var(--color-1st), 0.075);

            i {
                @include vertical-align();
                left: 16px;
            }

            span {
                font-weight: inherit;
            }
        }
    }

    .ps-form__links {
        text-align: center;

        a {
            font-weight: 500;
            color: var(--color-1st);
            text-transform: capitalize;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .ps-form__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .ps-checkbox {
            label {
                text-transform: capitalize;
            }
        }

        a {
            justify-self: end;
            font-weight: 500;
            color: var(--color-1st);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .ps-form__submit {
        .ps-btn {
            width: 100%;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 60px 30px;
        .ps-form__top {
            h3 {
                font-size: 36px;
            }
        }

        .ps-form__submit {
            .ps-btn {
                height: 64px;
                font-size: 18px;

            }
        }
    }

}

.ps-form--user-information {
    .ps-form__genders {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-gap: 16px;
    }

    .ps-form__heading {

    }

    .ps-form__avatar {
        max-width: 200px;
        margin: 0 auto;

        .ps-form__image {
            margin-bottom: 24px;
            overflow: hidden;
            border-radius: var(--border-radius-medium);
        }

        .ps-btn {
            width: 100%;
            height: 60px;
        }
    }

    .ps-form__submit {
        display: grid;
        grid-gap: 8px;
    }

    @media screen and (min-width: 768px) {
        .ps-form__submit {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 16px;

            .ps-btn {
                min-width: 200px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .ps-form__avatar {
            margin-bottom: 30px;
        }
        .ps-form__fields {
            .row {
                display: flex;
                flex-flow: column-reverse wrap;
            }
        }
    }
}

.ps-form--dashboard-search {
    position: relative;

    button {
        @include vertical-align();
        right: 0;
        background-color: transparent;
        border: none;

    }

    input {
        border: none;
        color: var(--color-text);
        font-size: 16px;
        border-radius: 0;
        border-bottom: 1px solid var(--color-border);
        padding-left: 0;
        font-weight: 400;
        @include placeholder() {
            color: var(--color-text);
            font-size: 16px;
            font-weight: 400;
        }
    }
}

.ps-form--dashboard {
    .ps-form__btn {
        max-width: 200px;

        i {
            margin-right: 16px;
        }
    }

    .ps-form__block {
        figcaption {
            margin-bottom: 30px;
            font-size: 18px;
            font-weight: 500;
            color: $color-heading;
        }
    }

    .ps-form__submit {
        padding-top: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        .ps-btn {
            min-width: 200px;
            text-align: center;
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-form__submit {
            justify-content: flex-end;
        }
    }

    @media screen and (max-width: 480px) {
        .ps-form__submit {
            flex-flow: column nowrap;

            .ps-btn {
                width: 100%;
            }
        }
    }
}

.ps-form--join-us {
    .dropzone {
        min-height: 50px;
        border-radius: var(--border-radius-medium);
        border-width: 1px;
        border-color: var(--color-border);

        .dz-message {
            margin: 0;
        }
    }

    .ps-form__top {
        padding-bottom: 32px;

        h3 {
            font-size: 24px;
        }
    }

    .ps-form__upload {
        height: auto;

        .custom-file-label {
            height: 48px;

            margin: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 16px;

            &:after {
                display: flex;
                justify-content: center;
                align-content: center;
                height: 100%;
                padding: 0 16px;
            }
        }
    }

    .ps-form__bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        padding-top: 32px;

        .ps-btn {
            min-width: 140px;
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-form__top {
            h3 {
                font-size: 36px;
            }
        }
    }
}
