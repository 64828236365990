.ps-footer {
    .ps-widget--footer {
        margin-bottom: 30px;

        .ps-social-links {
            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__copyright {
        text-align: center;
        border-top: 1px solid rgba(#fff, 0.3);

        p {
            margin-bottom: 0;
            color: #fff;
            line-height: 1em;
        }
    }

    @media screen and (max-width: 1024px) {
        margin-bottom: 60px;
    }
}

.ps-footer--default {
    background-color: var(--color-bg-primary);

    .ps-footer__copyright {
        padding-bottom: 60px;
        padding-top: 32px;
    }

    .ps-footer__top {
        display: grid;
        padding-top: 80px;
        padding-bottom: 50px;

    }

    &.light {
        background-color: #fff;

        .ps-widget--footer {
            p {
                color: var(--color-heading);
            }

            .ps-social-links {
                a {
                    color: var(--color-heading);


                }
            }

            .ps-widget__links {
                li {
                    a {
                        color: var(--color-heading);

                        &:hover {
                            color: var(--color-text);
                        }
                    }
                }
            }
        }

        .ps-footer__copyright {
            p {
                color: var(--color-heading);
            }
        }

        .ps-footer__bottom {
            border-top: 1px solid rgba(#060505, 0.1)
        }

        .ps-form--widget-subscribe {
            .ps-form__bottom {
                p {
                    color: var(--color-text);

                    a {
                        color: var(--color-heading);

                        &:hover {
                            color: var(--color-1st);
                        }
                    }
                }
            }
        }
    }
    

    @media screen and (min-width: 768px) {
        .ps-widget-footer-contact {
            max-width: 255px;
        }

        .ps-footer__widgets {
            display: grid;
            grid-template-columns: 4fr 3fr 3fr;
        }
    }

    @media screen and (min-width: 1040px) {

        .ps-footer__top {
            grid-template-columns: 7fr 3fr;
            grid-gap: 30px;
            padding-top: 120px;
            padding-bottom: 70px;
        }

        .ps-footer__copyright {
            padding-top: 65px;
            padding-bottom: 122px;
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-footer__top {
            padding-top: 160px;
            padding-bottom: 100px;
        }
    }
}
