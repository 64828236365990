.ps-carousel {
    .owl-nav {
        @include vertical-align();
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 16px;
        height: 0;
        @include hidden;
        transition: all .4s ease;

        .owl-next, .owl-prev {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: var(--border-radius-medium);
            background-color: #f2f6f9 !important;
            transform: translateY(-50%);

            i {
                font-size: 16px;
                color: var(--color-1st);
            }

            &:hover {
                background-color: var(--color-1st) !important;

                i {
                    color: #ffffff;
                }
            }
        }
    }

    .owl-dots {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column-gap: 10px;

        .owl-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: alpha(var(--color-1st), 0.2);

            &.active {
                border-radius: 10px;
                width: 20px;
                background-color: var(--color-1st);
            }
        }
    }

    &:hover {
        .owl-nav {
            @include show;
        }

    }

    @media screen and (min-width: 1024px) {
        .owl-dots {
            display: none;
        }
    }
    @media screen and (max-width: 1023px) {
        .owl-nav {
            display: none;
        }
    }
}


