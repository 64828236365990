.ps-layout--grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &.with-skeleton {
        .ps-layout__item {
            padding: 10px;
            /*     border: none;*/
            .ps-skeleton--product {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (min-width: 480px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 1200px) {
        grid-gap: 30px;
        &[data-columns="2"] {
            grid-template-columns: repeat(2, 1fr);
        }
        &[data-columns="3"] {
            grid-template-columns: repeat(3, 1fr);
        }
        &[data-columns="4"] {
            grid-template-columns: repeat(4, 1fr);
        }
        &[data-columns="5"] {
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 20px;
        }
        &[data-columns="6"] {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}

.ps-layout--list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

.ps-layout--with-sidebar {
    display: grid;
    grid-template-columns:minmax(0, 1fr);

    @media screen and (min-width: 1024px) {
        grid-template-columns: minmax(0, 1fr) 255px;
        grid-gap: 30px;
        min-height: 80vh;

        .ps-layout__left {
            max-width: calc(1140px - 285px);
        }

        &.ps-reverse {
            grid-template-columns: 255px 1fr;

            .ps-layout__left {
                grid-column-start: 2;
            }

            .ps-layout__right {
                grid-column-start: 1;
                grid-row-start: 1;
            }

            .ps-layout__right {
                max-width: 992px;
            }
        }
    }

    @media screen and (min-width: 1366px) {
        grid-gap: 60px;
        grid-template-columns: 1fr 355px;

        .ps-layout__left {
            max-width: calc(1240px - 415px);
        }

        &.ps-reverse {
            grid-template-columns: 355px 1fr;
        }
    }

    @media screen and (min-width: 1600px) {
        grid-gap: 100px;
        .ps-layout__left {
            max-width: calc(1510px - 455px);
        }
    }

    @media screen and (max-width: 480px) {
        .ps-layout__right {
            max-width: 100vw;
        }
    }
}

.ps-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;

    @media screen and (min-width: 768px) {
        &[data-columns='2'] {
            grid-template-columns: repeat(2, 1fr);
        }

        &[data-columns='3'] {
            grid-template-columns: repeat(2, 1fr);
        }

        &[data-columns='4'] {
            grid-template-columns: repeat(3, 1fr);
        }

        &[data-columns='5'] {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media screen and (min-width: 1200px) {
        &[data-columns='3'] {
            grid-template-columns: repeat(3, 1fr);
        }

        &[data-columns='4'] {
            grid-template-columns: repeat(4, 1fr);
        }

        &[data-columns='5'] {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media screen and (min-width: 1366px) {

        &[data-columns='5'] {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}


