.ps-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000, 0.5);
    @include hidden;
    transition: all .25s ease;

    &__wrapper {
        background-color: #fff;
        overflow: auto;
    }

    @media screen and (max-width: 1280px) {
        &__wrapper {
            max-height: 500px;
        }
    }

    @media screen and (max-width: 1366px) {
        &__wrapper {
            max-height: 500px;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0 16px;
    }

    &.active {
        @include show;
    }
}

.ps-dialog--search {
    .ps-form--projects-search-extra {
        .ps-form__extra {

        }
    }

    .ps-dialog__wrapper {
        background-color: transparent;
    }
}
