.ps-drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 320px;
    height: 100vh;
    overflow-y: auto;
    z-index: 10001;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    grid-row-gap: 10px;
    background-color: #fff;
    transform: translateX(100%);
    padding: 0 16px;
    border-left: 1px solid #eaeaea;
    transition: transform .4s cubic-bezier(0.7, 0, .3, 1) 0s;
    @extend %list-reset;

    .ps-drawer__close {
        color: var(--color-text);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 20px;
        }
    }

    .ps-drawer__heading {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    .ps-drawer__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
    }

    .ps-drawer__bottom {

    }

    &.active {
        transform: translateX(0);
    }

}
