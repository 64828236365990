.breadcrumb {
    background-color: transparent;

    li {
        position: relative;
        display: inline-block;
        color: var(--color-heading);
        font-size: 14px;

        &:before {
            content: '/';
            margin: 0 10px;
            color: var(--color-text-2);
        }

        a {
            color: var(--color-text-2);
            font-size: 14px;

            &:hover {
                color: var(--color-heading);
            }
        }

        &:first-child {
            &:before {
                display: none;
            }
        }
    }
}
