.ps-dashboard {

    @extend %list-reset;

    .menu--single {
        .active {
            a {

                background-color: alpha(var(--color-1st), 0.075);

                span {
                    color: var(--color-1st);
                }
            }
        }
    }

    .ps-dashboard__heading {
        font-size: 36px;
        text-transform: capitalize;
    }

    .ps-dashboard__left {
        .ps-block--user-dashboard {
            margin-bottom: 32px;
            grid-grap: 16px;
        }
    }

    .ps-dashboard__sidebar {
        padding: 16px;
        background-color: var(--color-bg-gray);
        border-radius: var(--border-radius-medium);

        .menu--single {
            a {
                color: var(--color-heading);
                height: 48px;
                padding: 0 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: var(--border-radius-medium);

                i {
                    margin-right: 16px;
                }

                span {
                    font-weight: 400;
                }

                &:hover {
                    color: var(--color-1st);
                    background-color: alpha(var(--color-1st), 0.075);
                }
            }
        }
    }

    .ps-dashboard__right {
        min-height: 600px;
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 280px minmax(0, 1fr);
        margin-bottom: var(--section-padding-bottom);
        grid-gap: 16px;
    }

    @media screen and (min-width: 1280px) {
        grid-gap: 36px;
    }

    @media screen and (min-width: 1366px) {
        grid-gap: 48px;
    }

    @media screen and (min-width: 1440px) {
        grid-gap: 36px;
    }
    @media screen and (max-width: 768px) {
        .ps-dashboard__left {
            margin-bottom: 30px;
        }
    }
}

.ps-my-properties {

    @media screen and (min-width: 768px) {
        .ps-panel__actions {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 16px;
        }
    }
}
