.sub-toggle {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    cursor: pointer;
    transform-style: preserve-3d;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 1px;
        background-color: $color-heading;
    }

    &:before {
        @include transform(translateX(-50%));
    }

    &:after {
        @include transform(translateX(-50%) rotate(90deg));
    }

    &.active {
        &:after {
            display: none;
        }
    }
}

.sub-menu {
    min-width: 240px;
    z-index: 999;
    transition: all 0.25s ease;
    background-color: #fff;
    border: 1px solid #efefef;
    @include hidden;

    &:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        @include transform(translateX(-50%));
        @include triangle(12px, #fff, up);
        display: none;
    }

    > li {
        display: block;

        > a {
            display: inline-block;
            padding: 10px 20px;
            width: 100%;
            font-size: var(--font-size-default);
            color: var(--color-text);
            text-transform: capitalize;
            transition: all 0.8s $ease-out-expo;

            &:hover {
                color: $color-1st;
            }
        }

        &:last-child {
            a {
                border-bottom: 1px solid #eee;
            }
        }

        &:last-child {
            border-bottom: none;
        }


    }

    .menu-item-has-children {
        position: relative;

        > .sub-toggle {
            display: none;
        }

        > .sub-menu {
            position: absolute;
            top: 0;
            left: 100%;
            @include hidden;
            @include transform(translateY(30px));

            &:before {
                display: none;
            }
        }

        &:hover {
            > .sub-menu {
                @include show;
                @include transform(translateY(0));
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .menu-item-has-children {
            > .sub-menu {
                position: relative;
                @include show;
                top: 0;
                left: 0;
                @include transform(translateY(0));
            }
        }
    }
}

.mega-menu {
    background-color: #fff;
    border: 1px solid #efefef;
    transition: all 0.25s ease;

    .sub-menu--mega {
        display: none;
        background-color: transparent;
    }

    .mega-menu__column {
        > h4 {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 500;
            color: var(--color-heading);
        }
    }

    .menu--single {
        li {
            a {
                color: var(--color-text);

                &:hover {
                    color: var(--color-1st);
                }
            }
        }
    }

    &.with-4-columns {
        .mega-menu__wrapper {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.with-5-columns {
        .mega-menu__wrapper {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &.with-6-columns {
        .mega-menu__wrapper {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @media screen and (min-width: 1024px) {
        box-shadow: 0 2px 10px 0 rgba(#ccc, 0.25);
        border-radius: 0 0 5px 5px;
        .mega-menu__wrapper {
            padding: 30px;
            display: grid;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
            width: 100%;
            margin: 0 auto;
        }
        .mega-menu__wrapper {
            &[data-columns="4"] {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            &[data-columns="5"] {
                grid-template-columns: repeat(5, minmax(0, 1fr));
            }

            &[data-columns="6"] {
                grid-template-columns: repeat(6, minmax(0, 1fr));
            }
        }
    }

    @media screen and (max-width: 479px) {
        .mega-menu__column {
            h4 {
                margin-bottom: 0;
            }
        }
    }
}

.menu--desktop {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > li {
        margin-right: 40px;

        > a {
            position: relative;
            display: inline-block;
            font-size: 16px;
            padding: 19px 0 53px;
            font-weight: 400;
            line-height: 20px;
            color: var(--color-heading);
            text-transform: capitalize;
            transform-style: preserve-3d;

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: var(--color-1st);
                transform: translateY(-100%) scaleX(0);
                transform-origin: 100% 50%;
                transition: transform .75s $timing-2;
            }

            &:hover {
                //background-color: lightness(var(--color-bg-gray), 0.9);

                &:after {
                    transform: translateY(-100%) scaleX(1);
                    transform-origin: 0 % 50%;
                }

            }
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;
        }

        .sub-toggle {
            display: none;
        }

        &.menu-item-has-children {
            position: relative;

            .sub-toggle {
                margin-left: 5px;
                display: none;
            }

            > .sub-menu, .mega-menu {
                position: absolute;
                top: 100%;
                left: 0;
                @include hidden();
                border-radius: 0 0 5px 5px;
                @include transform(scale3d(1, 1, 0));

            }

            &:hover {
                .sub-toggle {
                    color: #ffffff;
                }

                > .sub-menu {
                    @include show;
                    @include transform(scale3d(1, 1, 1));
                }
            }
        }

        &.has-mega-menu {
            position: static;

            .mega-menu {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                z-index: 1000;
                @include transform(scale3d(1, 1, 0));
                @include hidden;
            }

            &:hover {
                .mega-menu {
                    @include show;
                    @include transform(scale3d(1, 1, 1));
                }
            }
        }
    }
}

.menu--single {
    li {
        a {
            position: relative;
            display: block;
            line-height: 24px;
            padding: 6px 0;
            font-size: 16px;
            color: $color-1st;
            background-color: transparent;

            &:hover {
                color: $color-2nd;
            }
        }
    }

    &.bold {
        li {
            a {
                font-weight: 600;
            }
        }
    }
}

.menu--topbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > li {
        border-right: 1px solid #d9dee8;
        padding: 0 25px;

        > a {
            text-transform: capitalize;
            font-size: 13px;
            line-height: 30px;
            color: $color-gray;

            &:hover {
                color: $color-2nd;
            }
        }
    }
}

.menu--accordion {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        padding-right: 0;

        > a {
            padding: 10px 0;
        }
    }

    .menu__toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;

        > * {
            flex-basis: 100%;
        }

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            max-width: 30px;
            margin-right: 0;
        }

        &:hover {
            background-color: rgba(#e4e4e4, 0.1);
        }
    }

    .menu__title {
        display: block;
        padding: 10px 20px;
        font-size: 11px;
        color: $color-text;
        font-weight: 300;
        line-height: 20px;
        text-transform: uppercase;
    }

    .menu__text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: $color-heading;

        .menu__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            font-size: 16px;
            border-radius: 6px;
            margin-right: 0.5em;
        }
    }

    .sub-menu {
        position: relative;
        top: 0;
        left: 0;
        list-style-type: none;
        max-width: 100%;
        width: 100%;
        border: none;
        box-shadow: none;
        padding-left: 10px;
        transform: translate(0, 0) scale3d(1, 1, 1) !important;
        display: block !important;
        @include show;

        .menu__text {
            position: relative;
            font-size: 16px;

            &:before {
                content: "";
                @include vertical-align();
                left: 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: rgba(#fff, 0.25);
            }
        }

        .menu__icon--down {
            color: $color-heading;
        }

        > li {
            > a {
                padding: 10px 12px;

                &:hover {
                    background-color: rgba(#ccc, 0.1);
                }
            }

            &:last-child {
                a {
                    border: none;
                }
            }
        }
    }

    .sub-menu-wrapper {
        > .sub-menu {
            background-color: transparent;
            box-shadow: none;
        }
    }

    .sub-menu,
    .sub-menu-wrapper {
        transition: height 0.25s $timing-1;
    }

    .menu__item {
        display: block;

        a {
            &:after {
                display: none;
            }
        }
    }

    .menu__item--has-children {
        span > a {
            color: #fff;
        }

        &.active {
        }
    }

    &.menu--accordion-white {
        .sub-menu {
            .menu__text {
                color: $color-heading;
            }
        }

        > .menu__item {
            > a {
                width: 100%;
                color: $color-heading;
                border-radius: 4px;

                &:hover {
                    background-color: rgba(#ccc, 0.1);
                }
            }

            > .menu__toggle {
                color: $color-heading;
            }
        }
    }
}

.menu--mobile {

    .sub-toggle {
        &:before, &:after {
            background-color: var(--color-text);
        }
    }

    .sub-menu, .mega-menu {
        display: none;
        position: relative;
        padding-left: 20px;
        transform: translate(0, 0);
        transition: all 0s ease;
        border: none;
        @include show;

        > li {
            > a {
                border: none;
                padding: 10px 20px;
            }
        }
    }

    .menu--single {
        padding-left: 16px;
        display: none;

        li {
            a {
                color: var(--color-text);
            }
        }
    }

    .sub-menu {
        > li {
            > a {
                padding-left: 0;
                padding-right: 0;
                font-size: 16px;
                color: var(--color-text);
            }
        }
    }

    .mega-menu {
        padding-left: 16px;

        .mega-menu__column {

            h4 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }

    > li {
        border-bottom: 1px solid var(--color-border);

        > a {
            position: relative;
            z-index: 10;
            display: block;
            padding: 15px 20px 15px 0;
            line-height: 20px;
            font-weight: 500;
            font-size: 16px;
            color: var(--color-heading);
            text-transform: capitalize;
        }

        &.menu-item-has-children:not(.has-mega-menu) {
            position: relative;

            .sub-toggle {
                position: absolute;
                top: 5px;
                right: 0;
                width: 40px;
                height: 40px;
                z-index: 20;
                display: inline-block;
            }
        }

        &.has-mega-menu {
            position: relative;

            > .sub-toggle {
                position: absolute;
                top: 5px;
                right: 0;
                width: 40px;
                height: 40px;
                z-index: 20;
                display: inline-block;
            }

            .mega-menu {
                display: none;
            }
        }

        &:last-child {
            border: none;
        }
    }
}
