.ps-countdown {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 250px;
    grid-gap: 10px;

    &__number {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4px;

        > span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--border-radius-small);
            width: 34px;
            height: 36px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            background-color: var(--color-warning);
        }
    }

}

.ps-block--site-features {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 30px 0;
    grid-gap: 30px;

    .ps-block__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-right: 1px solid var(--color-border);


        i {
            font-size: 48px;
            color: var(--color-1st);
        }

        p {
            margin-bottom: 0;
            padding-left: 20px;

            strong {
                font-weight: 600;
                font-size: 18px;
                text-transform: capitalize;
                color: var(--color-heading);
            }

            span {
                display: block;
                font-size: 14px;
            }
        }

        &:last-child {
            border-right: none;
        }
    }
}

.ps-block--category {
    position: relative;
    z-index: 9;
    overflow: hidden;

    > * {
        border-radius: var(--border-radius-large);
    }

    img {
        width: 100%;
        transition: all .4s ease;
        border-radius: var(--border-radius-large);
    }

    .ps-block__image {
        overflow: hidden;
        border-radius: var(--border-radius-large);
    }

    .ps-block__name {
        width: 100%;
        background-color: #fff;
        color: var(--color-heading);

        &:hover {
            color: #fff;
            background-color: var(--color-1st);
        }
    }

    .ps-block__overlay {
        @include max-area();
        z-index: 1;
        background-color: alpha(var(--color-1st), 0.25);
        @include hidden;
    }

    .ps-block__content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        width: 100%;
        z-index: 10;
        text-align: center;
    }

    &:hover {
        img {
            transform: scale(1.08);
        }

        .ps-block__overlay {
            @include show;
        }

        .ps-block__name {
            color: #fff;
            background-color: var(--color-1st);
            transition-delay: 0.1s;
        }
    }
}

.ps-block--property-type {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    background-color: #e5eff6;
    border-radius: var(--border-radius-medium);
    max-height: 270px;
    transition: all .4s ease;

    cursor: pointer;

    .ps-block__overlay {
        @include max-area();
        z-index: 9;
    }

    h5 {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: 500;
        line-height: 1em;
        color: var(--color-heading);
    }

    p {
        font-size: 13px;
        color: var(--color-text);
    }

    .ps-block__image {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 30px 16px 0;

        img {
            max-height: 80px;
            width: auto;
        }
    }

    .ps-block__content {
        padding: 30px 10px;
    }

    &:hover {
        background-color: #fff;
        box-shadow: 0 20px 40px 0 rgba(92, 117, 134, 0.25);
    }

    @media screen and (min-width: 1024px) {
        .ps-block__image {
            padding: 60px 16px 0;
        }

        .ps-block__content {
            padding: 36px 24px 42px;
        }
    }

    &.second {
        display: block;
        background-color: transparent;
        text-align: left;

        .ps-block__image {
            padding: 0 24px;
            justify-content: flex-start;
        }

        .ps-block__content {
            padding-bottom: 0;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

.ps-block--neighborhood {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 26px;

    h3 {
        line-height: 1.2em;
        margin-bottom: 20px;

        a {
            font-size: 22px;
            font-weight: 500;

            &:hover {

            }
        }
    }

    p {
        margin-bottom: 0;
        line-height: 1.6em;

        strong {
            color: var(--color-heading);
            font-weight: 500;
        }
    }

    .ps-block__overlay {
        @include max-area();
        z-index: 9;
        transition: all .25s ease;
        border-radius: var(--border-radius-medium);
    }

    .ps-block__image {
        position: relative;
        border-radius: var(--border-radius-medium);
    }

    &:hover {
        .ps-block__overlay {
            background-color: alpha(var(--color-1st), 0.25);
        }
    }
}

.ps-block--destination {
    transition: all .4s ease;
    border-radius: var(--border-radius-medium);

    h4 {
        line-height: 1.2em;
        margin-bottom: 8px;

        a {
            font-size: 22px;
            font-weight: 500;

            &:hover {

            }
        }
    }

    p {
        margin-bottom: 0;
        line-height: 1.6em;

        strong {
            color: var(--color-heading);
            font-weight: 500;
        }
    }

    .ps-block__overlay {
        @include max-area();
        z-index: 9;
        transition: all .25s ease;
        border-radius: var(--border-radius-medium);
    }

    .ps-block__image {
        position: relative;
        border-radius: var(--border-radius-medium);
    }

    .ps-block__content {
        padding: 30px;
        background-color: #fff;
        border-bottom-left-radius: var(--border-radius-medium);
        border-bottom-right-radius: var(--border-radius-medium);
    }

    &:hover {
        box-shadow: 0 20px 40px 0 rgba(92, 117, 134, 0.25);

        .ps-block__overlay {
            background-color: alpha(var(--color-1st), 0.25);
        }
    }

    @media screen and (max-width: 479px) {
        h4 {
            font-size: 18px;

            a {
                font-size: inherit;
            }
        }

        .ps-block__content {
            padding: 16px;
        }
    }

    @media screen and (max-width: 1366px) {
        .ps-block__content {
            padding: 20px;
        }
    }
}

.ps-block--site-score {
    max-width: 355px;
    text-align: center;
    padding: 90px 30px 70px;

    h3 {
        font-size: 130px;
        font-weight: 300;
        line-height: 1em;
        margin-bottom: 40px;
    }

    .ps-block__ratings {
        padding-bottom: 60px;

        .ps-block__rating-items {
            i {
                color: #00991d;
                margin-right: 4px;
                font-size: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
            color: var(--color-heading);
            font-weight: 500;
        }
    }

    .ps-block__footer {
        p {
            text-transform: uppercase;
            font-size: 14px;
            line-height: 1em;
            margin-bottom: 18px;
            font-weight: 500;
            color: #999;
        }
    }
}

.ps-block--testimonial {
    h4 {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        color: var(--color-heading);
    }

    .ps-block__bottom {
        padding-top: 60px;

        p {
            margin-bottom: 0;
            line-height: 1.6em;
            font-size: 20px;
            font-weight: 500;
            color: var(--color-heading);

            span {
                font-size: 14px;
                color: #999999;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        h4 {
            font-size: 30px;
        }
    }

    @media screen and (min-width: 1366px) {
        h4 {
            font-size: 36px;
        }
    }
}

.ps-block--stat {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ps-block__right {
        flex-basis: 100%;
    }

    h3 {
        margin-right: 30px;
        margin-bottom: 0;
        line-height: 1em;
        font-size: 48px;
        color: var(--color-heading);
        font-weight: 400;
    }

    p {
        margin: 0;
        color: #999999;
        font-size: 16px;
        text-transform: uppercase;
    }

    @media screen and (min-width: 1024px) {

        h3 {
            margin-right: 45px;
            font-size: 72px;
        }

        p {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 1200px) {
        display: grid;
        h3 {
            margin-bottom: 10px;
            font-size: 48px;
        }

        p {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 767px) {
        h3 {
            font-size: 36px;
        }
        p {
            font-size: 14px;
        }
    }
}

.ps-block--post-author {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 30px;
    padding: 25px 0;

    figure {
        figcaption {
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 500;
            color: var(--color-heading);
            line-height: 1em;
        }

        p {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--color-text-2);
        }
    }

    .ps-block__links {
        position: relative;
        left: -10px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
            width: 32px;
            height: 32px;
            border-radius: var(--border-radius-medium);

            &:hover {
                background-color: alpha(var(--color-1st), 0.05);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ps-block__left {
        img {
            max-width: 80px;
            border-radius: 50%;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 50px 0;
        .ps-block__right {
            p {
                font-size: 16px;
            }
        }
    }
}

.ps-block-post-comment {
    display: grid;


    .ps-block__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        h4 {
            margin: 0;
            line-height: 1em;
            font-size: 20px;
            font-weight: 500;
            color: var(--color-heading);
        }

        span {
            color: var(--color-text-2);
            text-transform: uppercase;
            font-size: 12px;
            line-height: 1em;
        }

    }

    .ps-block__action {
        display: inline-block;
        vertical-align: top;
        padding: 0 1em;
        font-size: 12px;
        line-height: 26px;
        text-transform: uppercase;
        color: var(--color-heading);
        border: 1px solid #ccc;
        border-radius: var(--border-radius-medium);
        background-color: transparent;

        &:hover {
            color: var(--color-1st);
            border-color: var(--color-1st);
        }
    }

    .ps-block__children {
        padding-top: 30px;
    }

    .ps-block__content {
        p {
            font-size: 16px;
        }
    }

    .ps-block__left {
        img {
            border-radius: 50%;
        }
    }

    @media screen and (min-width: 768px) {
        grid-template-columns: 80px 1fr;
        grid-gap: 30px;
    }

    @media screen and (min-width: 1024px) {
        .ps-block__content {
            p {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .ps-block__left {
            padding-bottom: 20px;
        }
        .ps-block__children {
            padding-left: 20px;
        }
    }
}

.ps-block--team-member {
    cursor: default;
    box-shadow: 0 20px 50px 0 rgba(70, 82, 91, 0.1);
    transition: all 0.25s ease;
    position: relative;

    .ps-block__image {
        border-radius: var(--border-radius-medium);
    }

    .ps-block__top {
        position: static;
        z-index: 1;

        .ps-block__information {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 30px;
            z-index: 9;
            background-color: #fff;
            @include hidden;
            transition: all .25s ease;

            p {
                font-size: 16px;
            }
        }
    }

    .ps-block__content {
        padding: 16px 10px;
        position: relative;
        z-index: 1;

        h4 {
            margin-bottom: 0;
            line-height: 1.4em;
            font-size: 18px;
            font-weight: 500;
            color: var(--color-heading);
        }

        p {
            margin-bottom: 0;
        }
    }

    &:hover {
        box-shadow: 0 50px 80px 0 rgba(70, 82, 91, 0.25);

        .ps-block__information {
            @include show;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-block__content {
            padding: 30px;
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-block__content {
            p {
                font-size: 16px;
            }
        }
    }

}

.ps-block--service {
    h4 {
        font-size: 24px;

    }

    .ps-block__image {
        img {
            height: 120px;
        }
    }

    .ps-block__top {
        padding-bottom: 30px;
    }

    .ps-block__content {

        h4 {
            margin-bottom: 30px;
            line-height: 1.2em;
            font-size: 24px;
            font-weight: 500;
            color: var(--color-heading);
        }

        p {
            font-size: 14px;
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-block__top {
            padding-bottom: 60px;
        }

        h4 {
            font-size: 30px;
        }

        .ps-block__content {

            h4 {
                font-size: 30px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

.ps-block--contact-offices {
    .ps-block__heading {
        margin-bottom: 0;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--color-text-2);
        font-weight: 400;
    }

    .ps-block__item {
        max-width: 250px;

        figcaption {
            line-height: 1.2em;
            font-weight: 500;
            color: var(--color-heading);
            margin-bottom: 26px;
            font-size: 18px;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1.8em;
            color: var(--color-text);

            span {
                display: block;

                a {
                    &:hover {
                        text-decoration: underline;
                        color: var(--color-1st);
                    }
                }
            }
        }
    }

    .ps-block__header {
        padding-bottom: 30px;
    }

    .ps-block__column {
        margin-bottom: 35px;
    }

    .ps-block__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 768px) {
        .ps-block__item {
            figcaption {

            }

            p {
                font-size: 16px;

                a {
                    font-size: inherit;
                }
            }
        }

        .ps-block__column {
            margin-bottom: 70px;
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-block__header {
            padding-bottom: 60px;
        }

        .ps-block__item {
            figcaption {
                font-size: 24px;
            }
        }
    }
}

.ps-block--404 {
    text-align: center;

    h3 {
        margin-bottom: 20px;
        font-size: 36px;
    }

    p {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .ps-btn {
        margin: 0 auto;
        max-width: 230px;
    }

    .ps-block__image {

    }

    @media screen and (min-width: 768px) {
        h3 {
            margin-bottom: 20px;
            font-size: 48px;
        }
        p {
            font-size: 16px;
            margin-bottom: 60px;
        }
        .ps-block__content {
            position: relative;
            z-index: 10;
            top: -100px;
            margin-bottom: -100px;
        }
    }

    @media screen and (min-width: 1366px) {
        h3 {
            font-size: 54px;
        }
    }
}

.ps-block--faqs {
    .ps-block__top {
        padding-bottom: 25px;

        h4 {
            margin-bottom: 0;
            font-size: 16px;
            text-transform: uppercase;
            color: var(--color-text-2);
            line-height: 1em;
            font-weight: 400;
        }
    }

    .ps-accordion {
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-block__top {
            padding-top: 50px;
        }
    }
}

.ps-block--job {
    max-width: 370px;

    .ps-block__name {

        a {
            font-size: 18px;
            font-weight: 500;
            color: var(--color-heading);

            &:hover {
                text-decoration: underline;
            }
        }

    }

    .ps-block__desc {
        p {
            font-size: 14px;
        }
    }

    .ps-block__bottom {
        padding-top: 30px;

        p {
            text-transform: uppercase;
            color: var(--color-heading);
            font-weight: 500;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-block__desc {
            p {
                font-size: 16px;
            }
        }
    }

    @media screen and (min-width: 1024px) {


        .ps-block__name {
            a {
                font-size: 24px;
            }
        }
    }
}

.ps-block--career-detail {
    .ps-block__top {
        padding-bottom: 40px;

        h3 {
            font-size: 24px;
            margin-bottom: 15px;
        }

        p {
            font-size: 14px;
            margin-bottom: 0;
            text-transform: uppercase;
            color: var(--color-heading);
        }
    }

    .ps-block__content {
        p {
            font-size: 14px;
            color: var(--color-heading);
        }

        li {
            color: var(--color-heading);
        }
    }

    .ps-block__bottom {
        padding-top: 40px;

        .ps-btn {
            max-width: 200px;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-block__content {
            p {
                font-size: 16px;
            }

            li {
                font-size: 16px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-block__top {
            padding-bottom: 80px;

            h3 {
                margin-bottom: 30px;
                font-size: 48px;
            }
        }
    }
}

.ps-block--agent {
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);

    .ps-social-links {
        a {
            color: var(--color-text-2);

            &:hover {
                color: var(--color-1st);
            }
        }
    }

    .ps-block__name {
        display: block;
        line-height: 1.2em;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 500;
        color: var(--color-heading);

        &:hover {
            text-decoration: underline;
        }
    }

    .ps-block__position {
        font-size: 14px;
        margin-bottom: 0;

        a {
            color: var(--color-1st);
            display: inline-block;
            padding-left: 0.25em;
        }
    }

    .ps-block__meta {
        padding: 20px 0 10px;
    }

    .ps-block__image {
        border-radius: 5px 5px 0 0;
    }

    .ps-block__overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        @include max-area();
        z-index: 9;
        background-color: #99cdff;
        font-size: 80px;
        transition: all .25s ease;
        @include hidden;

        span {
            color: #fff;
        }
    }

    .ps-block__thumbnail {
        position: relative;
    }

    .ps-block__content {
        padding: 24px 28px;
    }

    &:hover {
        .ps-block__overlay {
            @include show;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-block__name {
            font-size: 22px;
        }
    }
}

.ps-block--agent-information {
    .ps-block__top {
        display: grid;
        padding-bottom: 30px;
    }

    .ps-block__top-left {
        h3 {
            font-size: 24px;
            line-height: 1em;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;

            a {
                color: var(--color-1st);
                font-size: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .ps-block__rating {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
            font-size: 13px;
            line-height: 1em;
            color: var(--color-text-2);
            margin-left: 0.5em;
        }
    }

    .ps-block__top-right {
        h4 {
            line-height: 1em;
            color: #669900;
            font-weight: 500;
        }


    }

    .ps-block__social-links {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            font-size: 14px;
            border-radius: var(--border-radius-medium);

            &:hover {
                background-color: alpha(var(--color-1st), 0.1);

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .ps-block__actions {
        display: flex;

        .ps-btn {
            max-width: 200px;
            width: 150px;
            margin-right: 10px;
        }
    }

    .ps-block__bottom {
        p {
            strong {
                font-weight: 500;
                color: #000000;
                padding-right: 0.5em;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        figure {
            padding-bottom: 20px;


            &:first-child {
                margin-bottom: 30px;
                border-bottom: 1px dashed var(--color-border);
            }

        }
    }


    @media screen and (min-width: 768px) {
        .ps-block__top-left {
            h3 {
                font-size: 36px;
            }

            p {
                font-size: 16px;
            }
        }

        .ps-block__top-right {
            text-align: right;
        }

        .ps-block__top {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .ps-block__bottom {
            p {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .ps-block__top-right {
            .ps-block__rating {
                justify-content: flex-start;
            }
        }

    }
}

.ps-block--agent-review {
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-column-gap: 30px;

    .ps-block__image {
        img {
            border-radius: 50%;
        }
    }

    .ps-block__header {
        display: grid;
        grid-template-columns: 1fr 70px;
        padding-bottom: 16px;

        h4 {
            margin-bottom: 0;
            font-size: 16px;
        }

        .ps-rating {
            i {
                font-size: 12px;
                margin-right: 2px;
            }
        }
    }

    .ps-block__bottom {
        padding-top: 15px;

        p {
            font-size: 14px;

            strong {
                font-weight: 500;
                color: var(--color-heading);
            }
        }
    }

    @media screen and (min-width: 768px) {
        grid-column-gap: 35px;

        .ps-block__header {
            padding-bottom: 25px;

            h4 {
                font-size: 20px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        grid-column-gap: 16px;

        .ps-block__header {
            grid-template-columns: 1fr;
        }
    }

}

.ps-block--agent-quick-info {
    display: grid;
    grid-template-columns:90px 1fr;
    grid-column-gap: 28px;

    h4 {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 1.2em;
    }

    p {
        margin-bottom: 0;
    }

    a {
        color: var(--color-1st);
        text-decoration: underline;
    }

    .ps-block__image {
        border-radius: var(--border-radius-medium);
        overflow: hidden;
    }


    .ps-block__content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.ps-block--project-nearby {
    .ps-block__heading {
        margin-bottom: 40px;
        font-size: 20px;
        fw4500display: grid;
        font-weight: 500;
        color: var(--color-heading);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        i {
            margin-right: 20px;
        }
    }

    .ps-block__item-left {
        p {
            margin-bottom: 0;
            font-weight: 500;
            color: var(--color-heading);
        }

        span {
            display: block;
            font-weight: 400;
            font-size: 13px;
            color: var(--color-text);
        }
    }

    .ps-block__item-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ps-rating {
            i {
                font-size: 12px;
            }
        }

        small {
            display: inline-block;
            font-size: 13px;
            margin-left: 6px;
            color: var(--color-text-2);
        }
    }

    .ps-block__item {
        display: grid;
        grid-template-columns: 1fr 120px;
        grid-column-gap: 30px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ps-block--project-reviews {
    > .ps-block__top {
        padding-bottom: 25px;

        p {
            margin: 0;

            strong {
                color: var(--color-success);
                margin-right: 10px;
                font-weight: 500;
                font-size: 20px;
            }

            span {
                font-size: 13px;
            }

            .ps-rating {
                margin-right: 10px;

                i {
                    font-size: 13px;
                }
            }
        }
    }

    > .ps-block__bottom {
        .ps-form--agent-review {
            margin-top: 35px;
        }
    }

    .ps-block--agent-review {
        padding-bottom: 36px;
        margin-bottom: 36px;
        border-bottom: 1px solid var(--color-border);

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    @media screen and (min-width: 1024px) {
        > .ps-block__top {
            padding-bottom: 50px;
        }

        > .ps-block__bottom {
            .ps-form--agent-review {
                margin-top: 70px;
            }
        }
    }
}

.ps-block--walkscore {
    .ps-block__top {
        padding-bottom: 20px;

        p {
            margin-bottom: 0;
            color: var(--color-heading);
        }
    }

    .ps-block__item {
        figure {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column nowrap;
            width: 90px;
            height: 90px;
            border-radius: 20px;
            margin-bottom: 20px;

            span {
                margin-bottom: 5px;
                color: var(--color-success);
                font-size: 42px;
                line-height: 1em;
            }

            small {
                font-size: 10px;
                color: var(--color-heading);
                text-transform: uppercase;
            }
        }

        .ps-block__item-bottom {
            h5 {
                font-size: 18px;
                margin-bottom: 0;
                line-height: 1.4em;
            }

            p {
                font-size: 14px;
                margin-bottom: 0;
                color: var(--color-text-2);
            }
        }
    }

    .ps-block__links {
        margin-bottom: 20px;

        a {
            text-transform: uppercase;
            font-size: 14px;
            color: var(--color-text);
            margin-right: 40px;

            &.active, &:hover {
                color: var(--color-1st);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ps-block__items {
        display: grid;
        grid-row-gap: 20px;
        padding-bottom: 20px;
        grid-template-columns: repeat(2, 1fr);
    }

    .ps-block__bottom {

        p {
            font-size: 16px;
            color: var(--color-1st);
            margin-bottom: 25px;

            a {
                text-decoration: underline;
            }

            i {
                margin-right: 20px;
            }
        }

        .form-group {
            position: relative;

            > i {
                @include vertical-align();
                right: 10px;
            }
        }
    }

    @media screen and (min-width: 1204px) {
        .ps-block__top {
            padding-bottom: 40px;

            p {
                font-size: 20px;
            }
        }

        .ps-block__links {
            margin-bottom: 40px;
        }

        .ps-block__items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            padding-bottom: 40px;
        }
    }
}

.ps-block--mortgage-chart {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-column-gap: 10px;

    .ps-block__chart {
        position: relative;

        .ps-block__chart-stat {
            @include center();
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            strong {
                line-height: 1em;
                font-size: 24px;
                font-weight: 500;
            }

            span {
                font-size: 14px;
                color: var(--color-text);
            }
        }
    }

    .ps-block__item {
        position: relative;
        margin-bottom: 20px;
        padding-left: 20px;
        font-size: 14px;

        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--color-1st);
        }

        span {
            display: block;
            line-height: 1em;
            margin-bottom: 10px;
        }

        strong {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-heading);
        }

    }
}

.ps-block--gallery-image {
    border-radius: var(--border-radius-medium);
    overflow: hidden;
}

.ps-block--user-dashboard {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 16px;

    .ps-block__content {
        align-self: center;

        h4 {
            font-size: var(--font-size-default);
            font-weight: 400;

            strong {
                font-weight: 500;
            }
        }
    }
}

.ps-block--notification {
    .ps-block__icon {
        padding: 0;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 30px;
            color: var(--color-text-2);
        }
    }

    h4 {
        small {
            margin-left: 20px;
            font-size: 0.6em;
            text-transform: uppercase;
            color: var(--color-text-2);
        }
    }

    .ps-block__actions {
        display: flex;
        align-items: center;
        gap: 16px;

        a {
            padding: 0 16px;
            height: 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .ps-btn--danger {
            background-color: alpha(var(--color-danger), 0.15);
            color: var(--color-danger);

            &:hover {
                color: #fff;
                background-color: alpha(var(--color-danger), 1);
            }

        }
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 80px minmax(0, 1fr);
        .ps-block__left {

        }
    }
}
